import { FC } from "react";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons"; */
import "./support-page.css";

export const Support: FC = () => {
  /* return (
    <button className="support-container">
      <FontAwesomeIcon icon={faQuestion} />
    </button>
  ); */

  return <></>;
};
