import { ServiceUrl } from "../constants/url";
import { Product, ProductLite } from "../models/product.interface";
import { FetchService } from "./fetch.service";

export abstract class ProductService {
  static async getAll(
    sessionToken: string,
    query?: { name?: string; limit?: number }
  ): Promise<Product[]> {
    return FetchService.get<Product[]>({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/product`,
      query,
    });
  }

  static async getAllLite(
    sessionToken: string,
    query?: { name?: string; limit?: number }
  ): Promise<ProductLite[]> {
    return FetchService.get<ProductLite[]>({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/product`,
      query: { ...(query ?? {}), lite: true },
    });
  }

  static async load(sessionToken: string): Promise<void> {
    return FetchService.post({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/product`,
      body: {},
    });
  }
}
