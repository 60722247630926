import { createContext, Context } from "react";
import { Shop } from "../../core/models/shop.interface";
import { ShopifyEmbeddedParams } from "../../core/models/shopify-embedded-params.interface";
import { InitialConfigStatus } from "../../core/models/validate-response.interface";
import { ConfigService } from "../../core/services/config.service";

interface Props {
  shopifyEmbeddedParams: ShopifyEmbeddedParams;
  shop: Shop;
  setShop: (shop: Shop) => void;
  initialConfigStatus: InitialConfigStatus;
  setInitialConfigStatus: (initialConfigStatus: InitialConfigStatus) => void;
  loading: boolean;
}

export const EmptyShop: Shop = {
  createdAt: "",
  domain: "",
  id: "",
  subscriptions: [],
  updatedAt: "",
};

const ConfigContext: Context<Props> = createContext<Props>({
  shopifyEmbeddedParams: ConfigService.getShopifyEmbeddedParams(),
  initialConfigStatus: {
    manualPaymentConfigured: false,
    paymentGatewayConfigured: false,
    productsSynchronized: false,
    subscribed: false,
  },
  shop: EmptyShop,
  loading: false,
  setShop: () => {},
  setInitialConfigStatus: () => {},
});

export default ConfigContext;
