import { Thumbnail } from "@shopify/polaris";

export const CocoImage = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Thumbnail
        source="https://firebasestorage.googleapis.com/v0/b/coco-shopify-app-v2-dev.appspot.com/o/images%2Fcoco_radius.png?alt=media"
        size="large"
        alt="Black choker necklace"
      />
    </div>
  );
};
