import { Spinner } from "@shopify/polaris";

export const CircularLoading = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner accessibilityLabel="Spinner example" size="large" />
    </div>
  );
};
