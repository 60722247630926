import { FC, lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { TitleBar } from "@shopify/app-bridge-react";
import { Frame, Navigation } from "@shopify/polaris";
import { CircularLoading } from "./components/circular-loading/circular-loading";
import { Support } from "./pages/support/support-page";
import { TeamConfigPage } from "./pages/team-config/team-config-page";
import { CreateTeamConfigPage } from "./pages/team-config/create-team-config-page";
import { TeamsPage } from "./pages/list-teams/list-teams-page";
import { ViewTeamPage } from "./pages/view-team/view-team-page";
import { HomePage } from "./pages/home/home-page";
import {
  HomeMinor,
  ProductsMinor,
  TeamMajor,
  SettingsMinor,
} from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShopConfigPage } from "./pages/shop-config/shop-config";

const ConfigWrapper = lazy(() => import("./wrappers/config-wrapper"));
const TeamWrapper = lazy(() => import("./wrappers/team-wrapper"));
const HomeWrapper = lazy(() => import("./wrappers/home-wrapper"));

const AppRouter: FC = () => {
  return (
    <Suspense fallback={<CircularLoading />}>
      <BrowserRouter>
        <ConfigWrapper>
          <HomeWrapper>
            <TeamWrapper>
              <RouteSwitch />
            </TeamWrapper>
          </HomeWrapper>
        </ConfigWrapper>
      </BrowserRouter>
      <Support />
    </Suspense>
  );
};

export default AppRouter;

const RouteSwitch: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "navigation",
  });
  const navigate = useNavigate();

  return (
    <main>
      <Frame
        navigation={
          <Navigation location="/">
            <Navigation.Section
              items={[
                {
                  onClick: () => navigate("/home"),
                  label: t("home"),
                  icon: HomeMinor,
                  matchPaths: ["/home"],
                },
                {
                  onClick: () => navigate("/team-configs"),
                  label: t("team-configs"),
                  icon: ProductsMinor,
                  matchPaths: ["/team-configs"],
                },
                {
                  onClick: () => navigate("/teams"),
                  label: t("teams"),
                  icon: TeamMajor,
                  matchPaths: ["/teams"],
                },
                {
                  onClick: () => navigate("/config"),
                  label: t("config"),
                  icon: SettingsMinor,
                  matchPaths: ["/config"],
                },
              ]}
            />
          </Navigation>
        }
      >
        <TitleBar title={"Administrador"} />

        <Routes>
          <Route path="home" element={<HomePage />} />
          <Route path="team-configs" element={<TeamConfigPage />} />
          <Route
            path="team-configs/:teamConfigId"
            element={<CreateTeamConfigPage />}
          />
          <Route path="teams" element={<TeamsPage />} />
          <Route path="teams/:teamId" element={<ViewTeamPage />} />
          <Route path="config" element={<ShopConfigPage />} />
          <Route path="*" element={<Navigate to="/team-configs" replace />} />
        </Routes>
      </Frame>
    </main>
  );
};
