import { FC, useEffect, useState } from "react";
import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import "./init-config-page.css";
import { InitialConfigStatus } from "../../../core/models/validate-response.interface";
import { BaseConfigComponent } from "../shop-config/base-config-component";
import { Shop } from "../../../core/models/shop.interface";
import { EmptyShop } from "../../providers/config-context";
import { ShopService } from "../../../core/services/shop.service";

interface Props {
  initialConfigStatus: InitialConfigStatus;
}

export const InitConfigPage: FC<Props> = ({ initialConfigStatus }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [shop, setShop] = useState<Shop>(EmptyShop);

  const app = useAppBridge();

  useEffect(() => {
    getSessionToken(app).then(async (sessionToken) => {
      const shop = await ShopService.getShop(sessionToken);
      setShop(shop);
      setLoading(false);
    });
  }, [app]);

  return (
    <BaseConfigComponent
      initialConfigStatus={initialConfigStatus}
      shop={shop}
      setShop={setShop}
      loading={loading}
      title="Configuración inicial"
    />
  );
};
