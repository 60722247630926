import { Subscription } from "./subscription.interface";

export interface Shop {
  id: string;
  domain: string;
  paymentGatewayConfig?: PaymentGatewayConfig;
  manualPaymentConfig?: ManualPaymentConfig;
  subscriptions: Subscription[];
  createdAt: string;
  updatedAt: string;
}

export interface ManualPaymentConfig {
  active: boolean;
}

export enum PaymentGateway {
  COCO = "COCO",
  PAYU = "PAYU",
  WOMPI = "WOMPI",
}

export interface PaymentGatewayConfig {
  payUCredentials: null | string;
  wompiCredentials: null | string;
  paymentGateway: PaymentGateway;
}

export interface PayUCredentials {
  API_KEY: string;
  API_LOGIN: string;
  PUBLIC_KEY: string;
  MERCHANT_ID: string;
  ACCOUNT_ID: string;
}

export const EmptyPayUCredentials: PayUCredentials = {
  ACCOUNT_ID: "",
  API_KEY: "",
  API_LOGIN: "",
  MERCHANT_ID: "",
  PUBLIC_KEY: "",
};

export const MaskedPayUCredentials: PayUCredentials = {
  ACCOUNT_ID: "******",
  API_KEY: "**************************",
  API_LOGIN: "***************",
  MERCHANT_ID: "******",
  PUBLIC_KEY: "**************************",
};

export interface WompiCredentials {
  PUBLIC_KEY: string;
  PRIVATE_KEY: string;
  EVENTS_SECRET: string;
  INTEGRITY_SECRET: string;
}

export const EmptyWompiCredentials: WompiCredentials = {
  PUBLIC_KEY: "",
  PRIVATE_KEY: "",
  EVENTS_SECRET: "",
  INTEGRITY_SECRET: "",
};

export const MaskedWompiCredentials: WompiCredentials = {
  PUBLIC_KEY: "**************************",
  PRIVATE_KEY: "**************************",
  EVENTS_SECRET: "******************************",
  INTEGRITY_SECRET: "******************************",
};
