import { format, addHours } from "date-fns";
import { es } from "date-fns/locale";

export abstract class DateFormat {
  static fromDate(date: Date | number) {
    return format(date, "PPpp", { locale: es });
  }

  static fromString(date: string) {
    return this.fromDate(new Date(date));
  }

  static fromStringAndDuration(date: string, duration: number) {
    return this.fromDate(addHours(new Date(date), duration));
  }
}
