import { getApps, initializeApp } from "firebase/app";
import { ShopifyEmbeddedParams } from "../models/shopify-embedded-params.interface";

export abstract class ConfigService {
  static async initialize(onInitialize?: Function) {
    fetch("/__/firebase/init.json").then(async (response) => {
      // Firebase initialization
      if (!getApps().length) {
        initializeApp(await response.json());
      }

      if (onInitialize) {
        onInitialize();
      }
    });
  }

  static getShopifyEmbeddedParams(): ShopifyEmbeddedParams {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    return {
      query: window.location.search,
      hmac: params.get("hmac") ?? "",
      host: params.get("host") ?? "",
      shop: params.get("shop") ?? "",
      session: params.get("session") ?? "",
      code: params.get("code") ?? undefined,
      charge_id: params.get("charge_id") ?? undefined,
    };
  }
}
