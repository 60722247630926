import { FC, useContext, useEffect } from "react";
import { Page, DisplayText, Stack } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { StatisticCard } from "../../components/statistics/statistic-card";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { CircularLoading } from "../../components/circular-loading/circular-loading";
import HomeContext from "../../providers/home-context";

ChartJS.register(...registerables);

export const HomePage: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "home-page",
  });

  const { loading, homePageData, loadHomePageData } = useContext(HomeContext);

  const summaryStatisticsCards = homePageData.summaryStatistics.map(
    (statistic) => <StatisticCard statistic={statistic} />
  );

  // Calling loadHomePageData on every macrorender
  useEffect(() => loadHomePageData, [loadHomePageData]);

  return (
    <Page fullWidth title={t("title")}>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <DisplayText size="small" element="h2">
            {t("summary")}
          </DisplayText>
          <br />
          <Stack>{summaryStatisticsCards}</Stack>
          <br />
          <DisplayText size="small" element="h2">
            {t("avg-incomes-per-week")}
          </DisplayText>
          <br />
          <Bar
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
            data={{
              labels: homePageData.avgIncomesPerWeek.map(
                (avgIncome) => avgIncome.week
              ),
              datasets: [
                {
                  label: t("avg-incomes-per-week"),
                  borderWidth: 1,
                  data: homePageData.avgIncomesPerWeek.map(
                    (avgIncome) => avgIncome.avg
                  ),
                },
              ],
            }}
          />
        </>
      )}
    </Page>
  );
};
