import { enc, AES } from "crypto-js";
export abstract class Crypter {
  // Encrypt a string
  static encryptString(value: string, key: string) {
    const encrypted = AES.encrypt(value, key).toString();
    return encrypted;
  }

  // Decrypt a string
  static decryptString(encryptedValue: string, key: string) {
    const decrypted = AES.decrypt(encryptedValue, key).toString(enc.Utf8);
    return decrypted;
  }
}
