import { createContext, Context } from "react";
import { TeamConfig } from "../../core/models/team-config.interface";
import { Team } from "../../core/models/team.interface";

interface Props {
  teams: { [key: string]: Team };
  setTeams: (teamConfigs: { [key: string]: Team }) => void;
  setTeam: (teamConfig: Team) => void;
  getTeam: (id: string) => Team | undefined;
  loadTeams: () => void;
  loadingTeams: boolean;
  teamConfigs: { [key: string]: TeamConfig };
  setTeamConfigs: (teamConfigs: { [key: string]: TeamConfig }) => void;
  setTeamConfig: (teamConfig: TeamConfig) => void;
  getTeamConfig: (id: string) => TeamConfig | undefined;
  loadingTeamConfigs: boolean;
  loadTeamConfigs: () => void;
}

const TeamContext: Context<Props> = createContext<Props>({
  teams: {},
  setTeams: () => {},
  setTeam: () => {},
  getTeam: () => undefined,
  loadingTeams: false,
  loadTeams: () => {},
  teamConfigs: {},
  setTeamConfigs: () => {},
  setTeamConfig: () => {},
  getTeamConfig: () => undefined,
  loadingTeamConfigs: false,
  loadTeamConfigs: () => {},
});

export default TeamContext;
