import { deepEqual } from "@firebase/util";
import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import {
  Button,
  FormLayout,
  Modal,
  RadioButton,
  Stack,
  TextField,
} from "@shopify/polaris";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  EmptyPayUCredentials,
  EmptyWompiCredentials,
  MaskedPayUCredentials,
  MaskedWompiCredentials,
  PaymentGateway,
  PaymentGatewayConfig,
  PayUCredentials,
  Shop,
  WompiCredentials,
} from "../../../core/models/shop.interface";
import { ShopService } from "../../../core/services/shop.service";
import ConfigContext from "../../providers/config-context";

interface Props {
  initialValue?: PaymentGatewayConfig;
  updateShop: (shop: Shop) => void;
}

export const PaymentGatewayConfigModal: FC<Props> = ({
  initialValue,
  updateShop,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [value, setValue] = useState<PaymentGateway>(
    initialValue?.paymentGateway ?? PaymentGateway.COCO
  );

  const [payUCredentials, setPayUCredentials] = useState<PayUCredentials>(
    initialValue?.payUCredentials ? MaskedPayUCredentials : EmptyPayUCredentials
  );

  const [wompiCredentials, setWompiCredentials] = useState<WompiCredentials>(
    initialValue?.wompiCredentials
      ? MaskedWompiCredentials
      : EmptyWompiCredentials
  );

  const [initialPayUCredentials, setInitialPayUCredentials] =
    useState<PayUCredentials>(
      initialValue?.payUCredentials
        ? MaskedPayUCredentials
        : EmptyPayUCredentials
    );

  const [initialWompiCredentials, setInitialWompiCredentials] =
    useState<WompiCredentials>(
      initialValue?.wompiCredentials
        ? MaskedWompiCredentials
        : EmptyWompiCredentials
    );

  const app = useAppBridge();
  const { shopifyEmbeddedParams } = useContext(ConfigContext);

  useEffect(() => {
    setValue(initialValue?.paymentGateway ?? PaymentGateway.COCO);
  }, [initialValue?.paymentGateway]);

  // Update payUCredentials object on each backend response
  useEffect(() => {
    getSessionToken(app).then(async (sessionToken) => {
      const retrievedPayUCredentials = await ShopService.getPayUCredentials(
        sessionToken,
        { shop: shopifyEmbeddedParams.shop }
      );

      setInitialPayUCredentials(retrievedPayUCredentials);
      setPayUCredentials(retrievedPayUCredentials);
    });
  }, [app, shopifyEmbeddedParams.shop, initialValue?.payUCredentials]);

  // Update payUCredentials object on each backend response
  useEffect(() => {
    getSessionToken(app).then(async (sessionToken) => {
      const retrievedWompiCredentials = await ShopService.getWompiCredentials(
        sessionToken,
        { shop: shopifyEmbeddedParams.shop }
      );

      setInitialWompiCredentials(retrievedWompiCredentials);
      setWompiCredentials(retrievedWompiCredentials);
    });
  }, [app, shopifyEmbeddedParams.shop, initialValue?.wompiCredentials]);

  const setPayUCredential = useCallback(
    (credential: Partial<PayUCredentials>) => {
      setPayUCredentials({ ...payUCredentials, ...credential });
    },
    [payUCredentials]
  );

  const setWompiCredential = useCallback(
    (credential: Partial<WompiCredentials>) => {
      setWompiCredentials({ ...wompiCredentials, ...credential });
    },
    [wompiCredentials]
  );

  const handleChange = useCallback(
    (_checked, newValue: PaymentGateway) => {
      if (newValue !== PaymentGateway.PAYU) {
        setPayUCredentials(initialPayUCredentials);
      }

      if (newValue !== PaymentGateway.WOMPI) {
        setWompiCredentials(initialWompiCredentials);
      }

      setValue(newValue);
    },
    [initialPayUCredentials, initialWompiCredentials]
  );

  const isDisabled = useCallback(() => {
    const initialConfig: {
      payUCredentials: PayUCredentials;
      wompiCredentials: WompiCredentials;
      paymentGateway: PaymentGateway;
    } = {
      payUCredentials: initialPayUCredentials,
      wompiCredentials: initialWompiCredentials,
      paymentGateway: initialValue?.paymentGateway ?? PaymentGateway.COCO,
    };

    const currentConfig: {
      payUCredentials: PayUCredentials;
      wompiCredentials: WompiCredentials;
      paymentGateway: PaymentGateway;
    } = {
      payUCredentials: payUCredentials,
      wompiCredentials: wompiCredentials,
      paymentGateway: value,
    };

    let disabled = deepEqual(initialConfig, currentConfig);

    // Disabling button if any field is empty
    if (
      value === PaymentGateway.PAYU &&
      Object.values(payUCredentials).some((value) => !value)
    ) {
      disabled = true;
    }

    // Disabling button if any field is empty
    if (
      value === PaymentGateway.WOMPI &&
      Object.values(wompiCredentials).some((value) => !value)
    ) {
      disabled = true;
    }

    return disabled;
  }, [
    initialValue?.paymentGateway,
    payUCredentials,
    value,
    initialPayUCredentials,
    wompiCredentials,
    initialWompiCredentials,
  ]);

  const onSaveChanges = useCallback(() => {
    getSessionToken(app).then(async (sessionToken) => {
      setLoading(true);

      await ShopService.updatePaymentGatewayConfig(sessionToken, {
        body: { paymentGateway: value },
        payUCredentials:
          value === PaymentGateway.PAYU ? payUCredentials : undefined,
        wompiCredentials:
          value === PaymentGateway.WOMPI ? wompiCredentials : undefined,
        shop: shopifyEmbeddedParams.shop,
      }).then((shop) => updateShop(shop));

      setLoading(false);
      setActive(false);
    });
  }, [
    value,
    app,
    updateShop,
    payUCredentials,
    shopifyEmbeddedParams.shop,
    wompiCredentials,
  ]);

  return (
    <Modal
      activator={
        <Button size="slim" outline onClick={() => setActive(true)}>
          Editar pasarela de pagos
        </Button>
      }
      open={active}
      onClose={() => setActive(false)}
      title="Configurar pasarela de pagos"
      primaryAction={{
        content: "Confirmar cambios",
        onAction: onSaveChanges,
        disabled: isDisabled(),
        loading,
      }}
      secondaryActions={[
        {
          disabled: loading,
          content: "Cancelar",
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <RadioButton
            label="Pasarela de Compras Compartidas"
            checked={value === PaymentGateway.COCO}
            id={PaymentGateway.COCO}
            name="payment-gateways"
            onChange={handleChange}
          />
          <RadioButton
            label="Pasarela propia de PayU"
            helpText="Debes ingresar las credenciales de tu pasarela de pagos PayU."
            id={PaymentGateway.PAYU}
            name="payment-gateways"
            checked={value === PaymentGateway.PAYU}
            onChange={handleChange}
          />
          <RadioButton
            label="Pasarela propia de Wompi"
            helpText="Debes ingresar las credenciales de tu pasarela de pagos Wompi."
            id={PaymentGateway.WOMPI}
            name="payment-gateways"
            checked={value === PaymentGateway.WOMPI}
            onChange={handleChange}
          />
          {value === PaymentGateway.PAYU ? (
            <FormLayout>
              <TextField
                label="Merchant ID"
                value={payUCredentials.MERCHANT_ID}
                onChange={(value) => setPayUCredential({ MERCHANT_ID: value })}
                autoComplete="off"
              />
              <TextField
                label="Account ID"
                value={payUCredentials.ACCOUNT_ID}
                onChange={(value) => setPayUCredential({ ACCOUNT_ID: value })}
                autoComplete="off"
              />
              <TextField
                label="Llave Pública"
                type="password"
                value={payUCredentials.PUBLIC_KEY}
                onChange={(value) => setPayUCredential({ PUBLIC_KEY: value })}
                autoComplete="off"
              />
              <TextField
                label="API LOGIN"
                type="password"
                value={payUCredentials.API_LOGIN}
                onChange={(value) => setPayUCredential({ API_LOGIN: value })}
                autoComplete="off"
              />
              <TextField
                label="API KEY"
                type="password"
                value={payUCredentials.API_KEY}
                onChange={(value) => setPayUCredential({ API_KEY: value })}
                autoComplete="off"
              />
            </FormLayout>
          ) : value === PaymentGateway.WOMPI ? (
            <FormLayout>
              <TextField
                label="Public Key"
                value={wompiCredentials.PUBLIC_KEY}
                onChange={(value) => setWompiCredential({ PUBLIC_KEY: value })}
                autoComplete="off"
              />
              <TextField
                label="Private Key"
                value={wompiCredentials.PRIVATE_KEY}
                onChange={(value) => setWompiCredential({ PRIVATE_KEY: value })}
                autoComplete="off"
              />
              <TextField
                label="Events Secret"
                type="password"
                value={wompiCredentials.EVENTS_SECRET}
                onChange={(value) =>
                  setWompiCredential({ EVENTS_SECRET: value })
                }
                autoComplete="off"
              />
              <TextField
                label="Integrity Secret"
                type="password"
                value={wompiCredentials.INTEGRITY_SECRET}
                onChange={(value) =>
                  setWompiCredential({ INTEGRITY_SECRET: value })
                }
                autoComplete="off"
              />
            </FormLayout>
          ) : (
            <></>
          )}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
