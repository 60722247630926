import axios from "axios";
export abstract class FetchService {
  static buildHeaders(sessionToken: string) {
    return {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  static async get<T>(params: {
    sessionToken: string;
    url: string;
    query?: { [key: string]: any };
  }): Promise<T> {
    const response = await axios.get<T>(params.url, {
      headers: this.buildHeaders(params.sessionToken),
      params: params.query,
    });

    return response.data;
  }

  static async post<T>(params: {
    sessionToken: string;
    url: string;
    body: { [key: string]: any };
  }): Promise<T> {
    const response = await axios.post<T>(params.url, params.body, {
      headers: this.buildHeaders(params.sessionToken),
    });
    return response.data;
  }

  static async put<T>(params: {
    sessionToken: string;
    url: string;
    body: { [key: string]: any };
  }): Promise<T> {
    const response = await axios.put<T>(params.url, params.body, {
      headers: this.buildHeaders(params.sessionToken),
    });
    return response.data;
  }

  static async delete<T>(params: {
    sessionToken: string;
    url: string;
  }): Promise<T> {
    const response = await axios.delete<T>(params.url, {
      headers: this.buildHeaders(params.sessionToken),
    });

    return response.data;
  }
}
