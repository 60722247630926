import { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  Page,
  Button,
  Card,
  IndexTable,
  Filters,
  Select,
  TextStyle,
  EmptyState,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TeamContext from "../../providers/team-context";
import { DateFormat } from "../../utils/date-format.util";

enum SortValue {
  DISCOUNT = "discount",
  PRODUCTS = "products",
  CLOSED_TEAMS = "closed-teams",
  CREATED_AT = "created-at",
}

export const TeamConfigPage: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "team-config-page",
  });

  const navigate = useNavigate();

  const goToCreate = () => {
    navigate({ pathname: "new" });
  };

  const goToUpdate = (id: string) => {
    navigate({ pathname: id });
  };

  const { teamConfigs, loadingTeamConfigs, loadTeamConfigs } =
    useContext(TeamContext);

  // Calling loadTeamConfigs on every macrorender
  useEffect(() => loadTeamConfigs, [loadTeamConfigs]);

  const [queryValue, setQueryValue] = useState<string>();
  const [sortValue, setSortValue] = useState<string>("created-at");

  const sortOptions = [
    { label: t("discount"), value: SortValue.DISCOUNT },
    { label: t("products"), value: SortValue.PRODUCTS },
    {
      label: t("closed-teams"),
      value: SortValue.CLOSED_TEAMS,
    },
    { label: t("created-at"), value: SortValue.CREATED_AT },
  ];

  const teamConfigsFiltered = Object.values(teamConfigs)
    .filter((teamConfig) =>
      teamConfig.name.toLowerCase().includes(queryValue?.toLowerCase() ?? "")
    )
    .sort((teamConfigA, teamConfigB) => {
      if (sortValue === SortValue.DISCOUNT) {
        return teamConfigA.discount - teamConfigB.discount;
      } else if (sortValue === SortValue.PRODUCTS) {
        return teamConfigA.products.length - teamConfigB.products.length;
      } else if (sortValue === SortValue.CLOSED_TEAMS) {
        // We have to change this in order to comparte closed teams
        return teamConfigA.products.length - teamConfigB.products.length;
      } else {
        return teamConfigA.createdAt > teamConfigB.createdAt ? 1 : -1;
      }
    });

  const handleClearAll = useCallback(() => {
    setQueryValue(undefined);
    setSortValue("created-at");
  }, []);

  const rowMarkup = teamConfigsFiltered.map(
    ({ id, name, quantity, discount, products, createdAt, active }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <TextStyle variation="strong">{name}</TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>{discount * 100}%</IndexTable.Cell>
        <IndexTable.Cell>{`${quantity} ${t(
          "units"
        ).toLowerCase()}`}</IndexTable.Cell>
        <IndexTable.Cell>{products.length}</IndexTable.Cell>
        <IndexTable.Cell>{DateFormat.fromString(createdAt)}</IndexTable.Cell>
        <IndexTable.Cell>{active ? "Activo" : "Inactivo"}</IndexTable.Cell>
        <IndexTable.Cell>
          {
            <Button outline onClick={() => goToUpdate(id)}>
              {t("edit")}
            </Button>
          }
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <Page fullWidth title={t("title")}>
      <Card>
        <div style={{ padding: "16px", display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              onQueryChange={setQueryValue}
              onQueryClear={() => setQueryValue(undefined)}
              onClearAll={handleClearAll}
              filters={[]}
              queryPlaceholder={t("filter-label")}
            />
          </div>
          <div style={{ paddingLeft: "0.4rem" }}>
            <Select
              labelInline
              label="Sort by"
              options={sortOptions}
              value={sortValue}
              onChange={setSortValue}
            />
          </div>

          <div style={{ paddingLeft: "0.4rem" }}>
            <Button primary onClick={goToCreate}>
              {t("add")}
            </Button>
          </div>
        </div>
        <IndexTable
          resourceName={{
            plural: t("plural"),
            singular: t("singular"),
          }}
          itemCount={teamConfigsFiltered.length}
          emptyState={
            <EmptyState
              heading={t("empty-label")}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            />
          }
          loading={loadingTeamConfigs}
          hasMoreItems
          lastColumnSticky
          headings={[
            { title: t("name") },
            { title: t("discount") },
            { title: t("quantity") },
            { title: t("products") },
            { title: t("created-at") },
            { title: t("active") },
            { title: t("actions") },
          ]}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </Page>
  );
};
