import { FC, useContext } from "react";
import ConfigContext from "../../providers/config-context";
import { BaseConfigComponent } from "./base-config-component";

export const ShopConfigPage: FC = () => {
  // State for every config value status
  const { initialConfigStatus, shop, loading, setShop } =
    useContext(ConfigContext);

  return (
    <BaseConfigComponent
      initialConfigStatus={initialConfigStatus}
      shop={shop}
      setShop={setShop}
      loading={loading}
      title="Ajustes de la aplicación"
    />
  );
};
