import { createContext, Context } from "react";
import { Statistic } from "../../core/models/statistic.interface";
import { Team } from "../../core/models/team.interface";

export interface HomeData {
  summaryStatistics: Statistic[];
  avgIncomesPerWeek: { avg: number; week: string }[];
  lastTeams: Team[];
}

interface Props {
  homePageData: HomeData;
  setHomePageData: (homePageData: HomeData) => void;
  loadHomePageData: () => void;
  loading: boolean;
}

const HomeContext: Context<Props> = createContext<Props>({
  homePageData: { summaryStatistics: [], avgIncomesPerWeek: [], lastTeams: [] },
  setHomePageData: () => {},
  loadHomePageData: () => {},
  loading: false,
});

export default HomeContext;
