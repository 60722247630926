import { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  Page,
  Button,
  Card,
  FormLayout,
  TextField,
  Form,
  Checkbox,
  OptionList,
  Scrollable,
  ButtonGroup,
  Thumbnail,
  Stack,
  TextStyle,
  Tag,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import TeamConfigContext from "../../providers/team-context";
import { useNavigate, useParams } from "react-router-dom";
import { ProductLite } from "../../../core/models/product.interface";
import { TeamConfigService } from "../../../core/services/team-config.service";
import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { ProductService } from "../../../core/services/product.service";
import { CircularLoading } from "../../components/circular-loading/circular-loading";

export const CreateTeamConfigPage: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "create-team-config-page",
  });

  const navigate = useNavigate();

  const goToBack = () => {
    navigate("/team-configs");
  };

  const app = useAppBridge();

  const { getTeamConfig, setTeamConfig } = useContext(TeamConfigContext);
  const { teamConfigId } = useParams();
  const teamConfig = getTeamConfig(teamConfigId ?? "");

  const [checked, setChecked] = useState<boolean>(teamConfig?.active ?? true);
  const [name, setName] = useState<string>(teamConfig?.name ?? "");
  const [duration, setDuration] = useState<number>(teamConfig?.duration ?? 24);

  const [productIds, setProductIds] = useState<string[]>(
    teamConfig?.products?.map((product) => product.id) ?? []
  );

  const [search, setSearch] = useState<string>("");
  const [searchedProductIds, setSearchedProductIds] = useState<string[]>([]);

  const [discountPercent, setDiscountPercent] = useState<number>(
    (teamConfig?.discount ?? 0.1) * 100
  );
  const [quantity, setQuantity] = useState<number>(teamConfig?.quantity ?? 5);

  const [loading, setLoading] = useState<boolean>(false);

  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);

  const handleSubmit = async () => {
    setLoading(true);
    if (!teamConfig) {
      await createTeamConfig();
    } else {
      await updateTeamConfig();
    }
    setLoading(false);
  };

  const updateTeamConfig = async () => {
    const sessionToken = await getSessionToken(app);
    const updatedTeamConfig = await TeamConfigService.update(
      teamConfig!.id,
      { name, productIds, duration, discount: discountPercent / 100, quantity },
      sessionToken
    );
    setTeamConfig(updatedTeamConfig);
    goToBack();
  };

  const createTeamConfig = async () => {
    const sessionToken = await getSessionToken(app);
    const createdTeamConfig = await TeamConfigService.create(
      { name, productIds, duration, discount: discountPercent / 100, quantity },
      sessionToken
    );
    setTeamConfig(createdTeamConfig);
    goToBack();
  };

  const [products, setProducts] = useState<{ [id: string]: ProductLite }>({});

  const searchProducts = useCallback(
    (searchString: string) => {
      setSearch(searchString);
      setLoadingProducts(true);
      if (!searchString) {
        setSearchedProductIds([]);
      } else {
        const newSearchedProductIds = Object.values(products)
          .filter((product) =>
            product.name
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
          .map((product) => product.id);
        setSearchedProductIds(newSearchedProductIds);
      }
      setLoadingProducts(false);
    },
    [products]
  );

  const getProductsByIds = useCallback(
    (ids: string[]) => {
      const productsByIds = [];
      for (const id of ids) {
        if (products[id]) {
          productsByIds.push(products[id]);
        }
      }
      return productsByIds;
    },
    [products]
  );

  useEffect(() => {
    setLoadingProducts(true);
    getSessionToken(app).then(async (sessionToken) => {
      const retrievedProducts: { [id: string]: ProductLite } = {};
      (await ProductService.getAllLite(sessionToken)).forEach((product) => {
        retrievedProducts[product.id] = product;
      });
      setProducts(retrievedProducts);
      setLoadingProducts(false);
    });
  }, [app]);

  // Submit disabled button
  const disabled: boolean =
    quantity <= 0 ||
    discountPercent <= 0 ||
    productIds.length <= 0 ||
    duration <= 0 ||
    name.trim().length <= 0;

  return (
    <Page
      fullWidth
      title={teamConfig ? t("edit-title") : t("create-title")}
      breadcrumbs={[{ content: "Team Configs", onAction: goToBack }]}
    >
      <br />
      <Form onSubmit={handleSubmit}>
        <div style={{ maxWidth: "768px" }}>
          <FormLayout>
            <Checkbox
              label={t("active")}
              checked={checked}
              onChange={setChecked}
            />
            <TextField
              label={t("name")}
              onChange={(value) => setName(value.trim())}
              autoComplete="off"
              value={name}
              placeholder="Nombre del equipo"
              helpText="Este nombre no se mostrará a tus clientes."
            />
            <div>
              <p style={{ marginBottom: "0.25rem" }}>{t("products")}</p>
              <Card>
                <TextField
                  autoComplete="off"
                  label=""
                  value={search}
                  placeholder="Buscar productos"
                  onChange={searchProducts}
                />
                <Scrollable style={{ height: "240px" }}>
                  {loadingProducts ? (
                    <CircularLoading />
                  ) : (
                    <OptionList
                      title={t("products-label")}
                      onChange={(selected) => setProductIds(selected)}
                      options={(search.length > 0
                        ? getProductsByIds(searchedProductIds)
                        : Object.values(products)
                      ).map((product) => ({
                        value: product.id,
                        label: product.name,
                        id: product.id,
                        disabled:
                          product.teamConfigId &&
                          product.teamConfigId !== teamConfigId
                            ? true
                            : false,
                        media: (
                          <Thumbnail
                            source={product.image?.src}
                            alt={product.image?.alt ?? product.id}
                            key={product.id}
                            size="small"
                          />
                        ),
                      }))}
                      selected={productIds}
                      allowMultiple
                    />
                  )}
                </Scrollable>
              </Card>
              <div style={{ marginBottom: "0.5rem" }} />
              <Stack>
                <TextStyle variation="strong">
                  Productos seleccionados:{" "}
                </TextStyle>
                {productIds.length > 0
                  ? getProductsByIds(productIds).map((product) => (
                      <Tag>{product.name}</Tag>
                    ))
                  : "Ninguno"}
              </Stack>
            </div>

            <TextField
              type="number"
              label={t("duration")}
              max={96}
              min={12}
              value={duration.toString()}
              onChange={(value) => setDuration(Number(value))}
              autoComplete="off"
              suffix={t("hours").toLowerCase()}
            />
            <TextField
              type="number"
              label={t("discount")}
              max={100}
              min={0}
              step={1}
              value={discountPercent.toString()}
              onChange={(value) => setDiscountPercent(Number(value))}
              autoComplete="off"
              suffix={"%"}
            />
            <TextField
              type="number"
              label={t("quantity")}
              min={2}
              value={quantity.toString()}
              onChange={(value) => setQuantity(Number(value))}
              autoComplete="off"
              suffix={t("units").toLowerCase()}
            />
            <ButtonGroup>
              <Button onClick={goToBack}>{t("cancel")}</Button>
              <Button submit primary loading={loading} disabled={disabled}>
                {teamConfig ? t("edit") : t("add")}
              </Button>
            </ButtonGroup>
          </FormLayout>
        </div>
      </Form>
    </Page>
  );
};
