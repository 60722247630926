import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { Button, Checkbox, Modal } from "@shopify/polaris";
import { FC, useCallback, useState } from "react";
import { ManualPaymentConfig, Shop } from "../../../core/models/shop.interface";
import { ShopService } from "../../../core/services/shop.service";

interface Props {
  updateShop: (shop: Shop) => void;
  initialValue?: ManualPaymentConfig;
}

export const ManualPaymentConfigModal: FC<Props> = ({
  updateShop,
  initialValue,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<boolean>(initialValue?.active ?? false);

  const app = useAppBridge();

  const onSaveChanges = useCallback(() => {
    getSessionToken(app).then(async (sessionToken) => {
      setLoading(true);
      const shop = await ShopService.updateManualPaymentConfig(sessionToken, {
        active: value,
      });
      setLoading(false);
      updateShop(shop);
      setActive(false);
    });
  }, [value, app, updateShop]);

  return (
    <Modal
      small
      activator={
        <Button size="slim" outline onClick={() => setActive(true)}>
          Editar pagos contraentrega
        </Button>
      }
      open={active}
      onClose={() => setActive(false)}
      title="Configurar pagos contraentrega"
      primaryAction={{
        content: "Confirmar cambios",
        onAction: onSaveChanges,
        disabled: (initialValue?.active ?? false) === value,
        loading: loading,
      }}
      secondaryActions={[
        {
          disabled: loading,
          content: "Cancelar",
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <Checkbox
          label="Pagos contraentrega habilitados"
          checked={value}
          onChange={setValue}
        />
      </Modal.Section>
    </Modal>
  );
};
