import { ServiceUrl } from "../constants/url";
import {
  CreateTeamConfig,
  TeamConfig,
  UpdateTeamConfig,
} from "../models/team-config.interface";
import { FetchService } from "./fetch.service";

export abstract class TeamConfigService {
  static async getAll(sessionToken: string): Promise<TeamConfig[]> {
    return FetchService.get<TeamConfig[]>({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/team-config`,
    });
  }

  static async get(id: string, sessionToken: string): Promise<TeamConfig> {
    return FetchService.get<TeamConfig>({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/team-config/${id}`,
    });
  }

  static async create(
    createTeamConfig: CreateTeamConfig,
    sessionToken: string
  ): Promise<TeamConfig> {
    return FetchService.post<TeamConfig>({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/team-config`,
      body: createTeamConfig,
    });
  }

  static async update(
    id: string,
    updateTeamConfig: UpdateTeamConfig,
    sessionToken: string
  ): Promise<TeamConfig> {
    return FetchService.put<TeamConfig>({
      sessionToken,
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/team-config/${id}`,
      body: updateTeamConfig,
    });
  }
}
