import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ShopifyApp } from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: "es",
      debug: true,
      supportedLngs: ["es"],
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      ReactDOM.render(
        <React.StrictMode>
          <ShopifyApp />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
