import { FC, useContext } from "react";
import {
  Page,
  Badge,
  TextStyle,
  ProgressBar,
  Subheading,
  Card,
  ResourceList,
  ResourceItem,
  Avatar,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./view-team-page.css";
import TeamContext from "../../providers/team-context";
import { DateFormat } from "../../utils/date-format.util";

export const ViewTeamPage: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "view-team-page",
  });

  const navigate = useNavigate();

  const goToBack = () => {
    navigate("/teams");
  };

  const { teamId } = useParams();

  const { getTeam } = useContext(TeamContext);

  const team = getTeam(teamId ?? "");

  let currentQuantity = 0;
  team?.reserves.forEach((reserve) =>
    reserve.reserveVariants.forEach(
      (reserveVariant) => (currentQuantity += reserveVariant.quantity)
    )
  );

  return (
    <Page
      fullWidth
      title={t("title")}
      breadcrumbs={[{ content: "Teams", onAction: goToBack }]}
    >
      <div className="grid-container">
        <div className="grid-item">
          <TextStyle variation="strong">{t("team-code")}:</TextStyle>{" "}
          {team?.teamCode}
        </div>
        <div className="grid-item">
          <TextStyle variation="strong">{t("current-units")}:</TextStyle>
          &nbsp; {`${currentQuantity}/${team?.quantity}`} &nbsp;
          <ProgressBar
            size="small"
            progress={100 * (currentQuantity / (team?.quantity ?? 1))}
            animated={true}
            color="highlight"
          />
        </div>
        <div className="grid-item">
          <Badge status="warning" progress="partiallyComplete">
            {team?.teamStatus.name}
          </Badge>
        </div>
        <div className="grid-item">
          <TextStyle variation="strong">{t("init-status")}:</TextStyle>{" "}
          {DateFormat.fromString(team?.createdAt ?? new Date().toDateString())}
        </div>
        <div className="grid-item">
          <TextStyle variation="strong">{t("ends-in")}:</TextStyle>{" "}
          {DateFormat.fromStringAndDuration(
            team?.createdAt ?? new Date().toDateString(),
            team?.duration ?? 0
          )}
        </div>
      </div>
      <br />
      <div>
        <Subheading>{t("buyers")}:</Subheading>
      </div>
      <Card>
        <ResourceList
          resourceName={{ singular: "customer", plural: "customers" }}
          items={[
            {
              id: "1",
              name: "Thomas Saad",
              email: "tm.saad@comprascompartidas.com",
              entryDate: "25/02/2022 08:00:00",
              seeProductsAction: "",
            },
            {
              id: "2",
              name: "Nicolas Hernández",
              email: "nicolashernandez@comprascompartidas.com",
              entryDate: "25/02/2022 10:15:00",
              seeProductsAction: "",
            },
          ]}
          renderItem={(item) => {
            const { id, name, email, entryDate, seeProductsAction } = item;
            const media = <Avatar customer size="medium" name={name} />;
            const shortcutActions = [
              {
                content: t("view-reserved-products"),
                url: seeProductsAction,
              },
            ];

            return (
              <ResourceItem
                id={id}
                url={"#"}
                media={media}
                shortcutActions={shortcutActions}
                persistActions
              >
                <h3>
                  <TextStyle variation="strong">{name}</TextStyle>
                </h3>
                <div>
                  <TextStyle variation="strong">{t("email")}:</TextStyle>{" "}
                  {email}
                </div>
                <div>
                  <TextStyle variation="strong">{t("entry-date")}:</TextStyle>{" "}
                  {entryDate}
                </div>
              </ResourceItem>
            );
          }}
        />
      </Card>
    </Page>
  );
};
