import { Card, DisplayText } from "@shopify/polaris";
import { FC } from "react";
import { Statistic } from "../../../core/models/statistic.interface";

interface Props {
  statistic: Statistic;
}

export const StatisticCard: FC<Props> = ({ statistic }) => {
  return (
    <Card>
      <Card.Section title={statistic.title.es}>
        <DisplayText size="small">{statistic.formattedValue}</DisplayText>
      </Card.Section>
    </Card>
  );
};
