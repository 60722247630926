import { FC, useState } from "react";
import {
  Page,
  Button,
  Card,
  TextStyle,
  Banner,
  Caption,
} from "@shopify/polaris";
import { CircularLoading } from "../../components/circular-loading/circular-loading";
import { PaymentGatewayConfigModal } from "./payment-gateway-config-modal";
import { ManualPaymentConfigModal } from "./manual-payment-config-modal";
import { InitialConfigStatus } from "../../../core/models/validate-response.interface";
import { PaymentGateway, Shop } from "../../../core/models/shop.interface";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { SubscriptionService } from "../../../core/services/subscription.service";

interface Props {
  title: string;
  initialConfigStatus: InitialConfigStatus;
  shop: Shop;
  loading: boolean;
  setShop: (shop: Shop) => void;
}

export const BaseConfigComponent: FC<Props> = ({
  title,
  initialConfigStatus,
  shop,
  loading,
  setShop,
}) => {
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const app = useAppBridge();

  // Call the subscription service
  const subscribe = async () => {
    setSubscribeLoading(true);
    const sessionToken = await getSessionToken(app);
    SubscriptionService.subscribe(sessionToken).then((data) => {
      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.REMOTE, data.confirmationUrl);
    });
  };

  return (
    <Page fullWidth title={title}>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <Card>
            <Card.Section title="1. Sincronización de productos">
              <Banner
                status={
                  initialConfigStatus.productsSynchronized
                    ? "success"
                    : "warning"
                }
              >
                <TextStyle variation="strong">
                  {initialConfigStatus.productsSynchronized
                    ? "Productos sincronizados satisfactoriamente."
                    : "No se han sincronizado los productos correctamente. Contacta soporte."}
                </TextStyle>
                <Caption>
                  Los productos de tu tienda se sincronizan automáticamente con
                  los sistemas de Compras Compartidas.
                </Caption>
              </Banner>
            </Card.Section>

            <Card.Section title="2. Configuración de pasarela de pagos">
              <Banner status="success">
                <TextStyle variation="strong">
                  {shop.paymentGatewayConfig?.paymentGateway ===
                  PaymentGateway.PAYU
                    ? "Tu propia pasarela de pagos con PayU está configurada."
                    : shop.paymentGatewayConfig?.paymentGateway ===
                      PaymentGateway.WOMPI
                    ? "Tu propia pasarela de pagos con Wompi está configurada."
                    : "La pasarela de Compras Compartidas está configurada."}{" "}
                  <Caption>
                    Puedes configurar tu propia pasarela o usar la pasarela de
                    pagos de Compras Compartidas.
                  </Caption>
                </TextStyle>
                <br />
                <PaymentGatewayConfigModal
                  initialValue={shop.paymentGatewayConfig}
                  updateShop={setShop}
                />
              </Banner>
            </Card.Section>

            <Card.Section title="3. Configuración de pago contraentrega">
              <Banner status="success">
                <TextStyle variation="strong">
                  El pago contraentrega está configurado y{" "}
                  {shop.manualPaymentConfig?.active
                    ? "ACTIVADO"
                    : "DESACTIVADO"}
                  .{" "}
                  <Caption>
                    Si habilitas esta opción, los pagos de tus órdenes deberán
                    ser recibidos durante la entrega del producto.
                  </Caption>
                </TextStyle>
                <br />
                <ManualPaymentConfigModal
                  initialValue={shop.manualPaymentConfig}
                  updateShop={setShop}
                />
              </Banner>
            </Card.Section>

            <Card.Section title="4. Subscripción">
              <Banner
                status={initialConfigStatus.subscribed ? "success" : "warning"}
              >
                <TextStyle variation="strong">
                  {initialConfigStatus.subscribed
                    ? "Subscripción realizada correctamente."
                    : "Es necesario realizar la subscripción."}
                  <Caption>
                    Usar las funcionalidades y beneficios de Compras Compartidas
                    requiere una subscripción mensual.
                  </Caption>
                </TextStyle>
                {initialConfigStatus.subscribed ? (
                  <></>
                ) : (
                  <>
                    <br />
                    <Button
                      size="slim"
                      outline
                      onClick={subscribe}
                      loading={subscribeLoading}
                    >
                      Subscribirse
                    </Button>
                  </>
                )}
              </Banner>
            </Card.Section>
          </Card>
          <br />
        </>
      )}
    </Page>
  );
};
