import { ServiceUrl } from "../constants/url";
import { ShopifySubscription } from "../models/shopify-subscription.interface";
import { Subscription } from "../models/subscription.interface";
import { FetchService } from "./fetch.service";

export abstract class SubscriptionService {
  static async subscribe(sessionToken: string): Promise<ShopifySubscription> {
    return FetchService.post({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/subscription/subscribe`,
      body: {},
      sessionToken,
    });
  }

  static async subscribed(
    shop: string,
    sessionToken: string
  ): Promise<Subscription> {
    return FetchService.get({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/subscription/subscribed`,
      sessionToken,
    });
  }
}
