import axios from "axios";
import { ServiceUrl } from "../constants/url";
import {
  InitialConfigStatus,
  ValidateResponse,
} from "../models/validate-response.interface";
import { FetchService } from "./fetch.service";

export abstract class AuthService {
  static async validate(
    data: { code?: string; chargeId?: string },
    sessionToken: string
  ): Promise<ValidateResponse> {
    return FetchService.post({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/auth/validate`,
      body: data,
      sessionToken,
    });
  }

  static async getConfig(sessionToken: string): Promise<InitialConfigStatus> {
    return FetchService.get({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/auth/initial-config`,
      sessionToken,
    });
  }

  static async load(): Promise<{ key: string }> {
    const response = await axios.get<{ key: string }>(
      `${ServiceUrl.SHOPIFY_API_SERVICE}/auth/load${window.location.search}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data ?? {};
  }
}
