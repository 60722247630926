import { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  Page,
  Card,
  Tabs,
  IndexTable,
  TextStyle,
  EmptyState,
  Button,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TeamContext from "../../providers/team-context";
import { Team } from "../../../core/models/team.interface";
import { DateFormat } from "../../utils/date-format.util";

export const TeamsPage: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "list-teams-page",
  });

  const navigate = useNavigate();

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const goToViewTeam = (teamId: string) => {
    navigate({ pathname: teamId });
  };

  const tabs = [
    {
      id: "open-teams",
      content: t("open-teams"),
      accessibilityLabel: "All customers",
    },
    {
      id: "closed-teams",
      content: t("closed-teams"),
    },
    {
      id: "expired-teams",
      content: t("expired-teams"),
    },
  ];

  const { teams, loadingTeams, loadTeams } = useContext(TeamContext);  

  // Calling loadTeams on every macrorender
  useEffect(() => loadTeams, [loadTeams]);

  const teamsByStatus: { [key: string]: Team[] } = {};
  (Object.values(teams) ?? []).forEach((team) => {
    if (!teamsByStatus[team.teamStatus.name]) {
      teamsByStatus[team.teamStatus.name] = [team];
    } else {
      teamsByStatus[team.teamStatus.name].push(team);
    }
  });

  const openTeams = teamsByStatus["OPEN"] ?? [];

  const closedTeams = teamsByStatus["CLOSED"] ?? [];

  const expiredTeams = teamsByStatus["EXPIRED"] ?? [];

  const openTeamRows = openTeams.map(
    (
      { id, teamCode, createdAt, teamStatus, duration, reserves, quantity },
      index
    ) => {
      let currentQuantity = 0;
      reserves.forEach((reserve) =>
        reserve.reserveVariants.forEach(
          (reserveVariant) => (currentQuantity += reserveVariant.quantity)
        )
      );
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>
            <TextStyle variation="strong">{teamCode}</TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>{teamStatus.name}</IndexTable.Cell>
          <IndexTable.Cell>{DateFormat.fromString(createdAt)}</IndexTable.Cell>
          <IndexTable.Cell>
            {DateFormat.fromStringAndDuration(createdAt, duration)}
          </IndexTable.Cell>
          <IndexTable.Cell>{`${currentQuantity}/${quantity}`}</IndexTable.Cell>{" "}
          {/* completeness */}
          <IndexTable.Cell>
            {
              <Button outline onClick={() => goToViewTeam(id)}>
                {t("view-team-action")}
              </Button>
            }
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const closedTeamRows = closedTeams.map(
    ({ id, teamCode, createdAt, teamStatus, duration, reserves }, index) => {
      let currentQuantity = 0;
      reserves.forEach((reserve) =>
        reserve.reserveVariants.forEach(
          (reserveVariant) => (currentQuantity += reserveVariant.quantity)
        )
      );

      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>
            <TextStyle variation="strong">{teamCode}</TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>{teamStatus.name}</IndexTable.Cell>
          <IndexTable.Cell>{DateFormat.fromString(createdAt)}</IndexTable.Cell>
          <IndexTable.Cell>
            {DateFormat.fromStringAndDuration(createdAt, duration)}
          </IndexTable.Cell>
          <IndexTable.Cell>{currentQuantity}</IndexTable.Cell> {/* currentQuantity */}
          <IndexTable.Cell>{reserves}</IndexTable.Cell> {/* buyers */}
          <IndexTable.Cell>
            {
              <Button outline onClick={() => goToViewTeam(id)}>
                {t("view-team-action")}
              </Button>
            }
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const expiredTeamRows = expiredTeams.map(
    (
      { id, teamCode, createdAt, teamStatus, duration, reserves, quantity },
      index
    ) => {
      let currentQuantity = 0;
      reserves.forEach((reserve) =>
        reserve.reserveVariants.forEach(
          (reserveVariant) => (currentQuantity += reserveVariant.quantity)
        )
      );
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>
            <TextStyle variation="strong">{teamCode}</TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>{teamStatus.name}</IndexTable.Cell>
          <IndexTable.Cell>{DateFormat.fromString(createdAt)}</IndexTable.Cell>
          <IndexTable.Cell>
            {DateFormat.fromStringAndDuration(createdAt, duration)}
          </IndexTable.Cell>
          <IndexTable.Cell>{`${currentQuantity}/${quantity}`}</IndexTable.Cell>{" "}
          {/* uncompleteness */}
          <IndexTable.Cell>
            {
              <Button outline onClick={() => goToViewTeam(id)}>
                {t("view-team-action")}
              </Button>
            }
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  return (
    <Page fullWidth title={t("title")}>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].id === "open-teams" ? (
            <Card.Section>
              <IndexTable
                resourceName={{
                  plural: t("plural"),
                  singular: t("singular"),
                }}
                loading={loadingTeams}
                itemCount={openTeams.length}
                emptyState={
                  <EmptyState
                    heading={t("empty-label")}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                  />
                }
                hasMoreItems
                lastColumnSticky
                headings={[
                  { title: t("team-code") },
                  { title: t("team-status") },
                  { title: t("team-init-date") },
                  { title: t("team-ends-in") },
                  { title: t("team-completeness") },
                  { title: t("team-actions") },
                ]}
                selectable={false}
              >
                {openTeamRows}
              </IndexTable>
            </Card.Section>
          ) : null}

          {tabs[selected].id === "closed-teams" ? (
            <Card.Section>
              <IndexTable
                resourceName={{
                  plural: t("plural"),
                  singular: t("singular"),
                }}
                loading={loadingTeams}
                itemCount={closedTeams.length}
                emptyState={
                  <EmptyState
                    heading={t("empty-label")}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                  />
                }
                hasMoreItems
                lastColumnSticky
                headings={[
                  { title: t("team-code") },
                  { title: t("team-name") },
                  { title: t("team-init-date") },
                  { title: t("team-end-date") },
                  { title: t("team-currentQuantity") },
                  { title: t("team-buyers") },
                  { title: t("team-actions") },
                ]}
                selectable={false}
              >
                {closedTeamRows}
              </IndexTable>
            </Card.Section>
          ) : null}

          {tabs[selected].id === "expired-teams" ? (
            <Card.Section>
              <IndexTable
                resourceName={{
                  plural: t("plural"),
                  singular: t("singular"),
                }}
                loading={loadingTeams}
                itemCount={expiredTeams.length}
                emptyState={
                  <EmptyState
                    heading={t("empty-label")}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                  />
                }
                hasMoreItems
                lastColumnSticky
                headings={[
                  { title: t("team-code") },
                  { title: t("team-name") },
                  { title: t("team-init-date") },
                  { title: t("team-expiration-date") },
                  { title: t("team-uncompleteness") },
                  { title: t("team-actions") },
                ]}
                selectable={false}
              >
                {expiredTeamRows}
              </IndexTable>
            </Card.Section>
          ) : null}
        </Tabs>
      </Card>
    </Page>
  );
};
