import "@shopify/polaris/build/esm/styles.css";
import { FC, useEffect, useState } from "react";
import { AppConfigV2, createApp } from "@shopify/app-bridge";
import esTranslations from "@shopify/polaris/locales/es.json";
import { Provider, useAppBridge } from "@shopify/app-bridge-react";
import { ConfigService } from "./core/services/config.service";
import { AuthService } from "./core/services/auth.service";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { AppProvider } from "@shopify/polaris";
import AppRouter from "./views/app-router";
import { Buffer } from "buffer";
import { Redirect } from "@shopify/app-bridge/actions";
import { InitConfigPage } from "./views/pages/init-config/init-config-page";
import { InitialConfigStatus } from "./core/models/validate-response.interface";
import { CocoImage } from "./views/components/circular-loading/coco-image";
import { ProductService } from "./core/services/product.service";

export const ShopifyApp: FC = () => {
  const [state, setState] = useState<{
    loading: boolean;
    initialConfigStatus: InitialConfigStatus;
  }>({
    loading: true,
    initialConfigStatus: {
      manualPaymentConfigured: false,
      paymentGatewayConfigured: false,
      productsSynchronized: false,
      subscribed: false,
    },
  });

  const [bridgeConfig, setBrideConfig] = useState<AppConfigV2>({
    host: "",
    apiKey: "",
  });

  useEffect(() => {
    ConfigService.initialize(async () => {
      const embeddedParams = ConfigService.getShopifyEmbeddedParams();
      const shopifyKey = await AuthService.load();
      const host =
        embeddedParams.host ||
        Buffer.from(`${embeddedParams.shop}/admin`).toString("base64");
      const appConfig: AppConfigV2 = {
        host,
        apiKey: shopifyKey.key,
      };
      const app = createApp(appConfig);
      const sessionToken = await getSessionToken(app);
      const validateResponse = await AuthService.validate(
        { code: embeddedParams.code, chargeId: embeddedParams["charge_id"] },
        sessionToken
      );

      // Check whether is necessary to update application
      if (validateResponse.redirectUrl) {
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.REMOTE, validateResponse.redirectUrl);
      }

      setBrideConfig(appConfig);
      setState({
        loading: false,
        initialConfigStatus: validateResponse.initialConfigStatus,
      });
    });
  }, []);

  return (
    <div>
      {state.loading ? (
        <CocoImage />
      ) : (
        <Provider config={bridgeConfig}>
          <App initialConfigStatus={state.initialConfigStatus} />
        </Provider>
      )}
    </div>
  );
};

interface Props {
  initialConfigStatus: InitialConfigStatus;
}

export const App: FC<Props> = ({ initialConfigStatus }) => {
  const isInitialConfigCompleted = Object.values(initialConfigStatus).every(
    (value: boolean) => value
  );

  const app = useAppBridge();

  useEffect(() => {
    getSessionToken(app).then(async (sessionToken) => {
      await ProductService.load(sessionToken);
    });
  }, [app]);

  return (
    <div>
      <AppProvider i18n={esTranslations}>
        {isInitialConfigCompleted ? (
          <AppRouter />
        ) : (
          <InitConfigPage initialConfigStatus={initialConfigStatus} />
        )}
      </AppProvider>
    </div>
  );
};
