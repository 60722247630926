import { ServiceUrl } from "../constants/url";
import {
  EmptyPayUCredentials,
  EmptyWompiCredentials,
  ManualPaymentConfig,
  PaymentGatewayConfig,
  PayUCredentials,
  Shop,
  WompiCredentials,
} from "../models/shop.interface";
import { FetchService } from "./fetch.service";
import { Crypter } from "../../views/utils/crypter.util";

export abstract class ShopService {
  static async getShop(sessionToken: string): Promise<Shop> {
    return FetchService.get<Shop>({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/shop`,
      sessionToken,
    });
  }

  static async getPayUCredentials(
    sessionToken: string,
    params: { shop: string }
  ): Promise<PayUCredentials> {
    const response = await FetchService.get<{ payUCredentials: string }>({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/shop/payu-credentials`,
      sessionToken,
    });
    if (!response.payUCredentials) {
      return EmptyPayUCredentials;
    } else {
      const decryptedCredentials = Crypter.decryptString(
        response.payUCredentials,
        params.shop
      );
      return JSON.parse(decryptedCredentials) as PayUCredentials;
    }
  }

  static async getWompiCredentials(
    sessionToken: string,
    params: { shop: string }
  ): Promise<WompiCredentials> {
    const response = await FetchService.get<{ wompiCredentials: string }>({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/shop/wompi-credentials`,
      sessionToken,
    });
    if (!response.wompiCredentials) {
      return EmptyWompiCredentials;
    } else {
      const decryptedCredentials = Crypter.decryptString(
        response.wompiCredentials,
        params.shop
      );
      return JSON.parse(decryptedCredentials) as WompiCredentials;
    }
  }

  static async updateManualPaymentConfig(
    sessionToken: string,
    body: ManualPaymentConfig
  ): Promise<Shop> {
    return FetchService.put<Shop>({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/shop/manual-payment-config`,
      sessionToken,
      body,
    });
  }

  static async updatePaymentGatewayConfig(
    sessionToken: string,
    params: {
      shop: string;
      body: Partial<PaymentGatewayConfig>;
      payUCredentials?: PayUCredentials;
      wompiCredentials?: WompiCredentials;
    }
  ): Promise<Shop> {
    // If PayUCredentials are totally filled
    if (
      !Object.values(params.payUCredentials ?? EmptyPayUCredentials).some(
        (credential) => !credential
      )
    ) {
      const encodedCredentials = Crypter.encryptString(
        JSON.stringify(params.payUCredentials),
        params.shop
      );

      // Setting encoded credentials for backend
      params.body.payUCredentials = encodedCredentials;
    }

    // If WompiCredentials are totally filled
    if (
      !Object.values(params.wompiCredentials ?? EmptyWompiCredentials).some(
        (credential) => !credential
      )
    ) {
      const encodedCredentials = Crypter.encryptString(
        JSON.stringify(params.wompiCredentials),
        params.shop
      );

      // Setting encoded credentials for backend
      params.body.wompiCredentials = encodedCredentials;
    }

    return FetchService.put<Shop>({
      url: `${ServiceUrl.SHOPIFY_API_SERVICE}/shop/payment-gateway-config`,
      sessionToken,
      body: params.body,
    });
  }
}
